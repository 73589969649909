import config from './../config'

var url = function (alias) {
    switch (alias) {
        case 'BASEURL':
            return config.DEBUG ? config.baseurl : config.baseurl_prod
        case "admin_report_url":
      	    return config.DEBUG ? config.admin_report_url : config.admin_report_url_prod;
        case 'TEMPPRODUCT':
            return config.DEBUG ? config.temprouct_url : config.temprouct_prod_url
        case 'COMPANYURL':
            return config.DEBUG ? config.company_url : config.company_url_prod
        case 'STATEURL':
            return config.DEBUG ? config.state_url : config.state_url_prod
        case 'GETFILEUPLOADS':
            return config.DEBUG ? config.get_file_uploads_url : config.get_file_uploads_prod_url
        case 'AFFINITIES':
            return config.DEBUG ? config.affinities_url : config.affinities_url_prod
        case 'AFFINITY_SEARCH':
            return config.DEBUG ? config.affinities_search_url : config.affinities_search_url_prod
        case 'AFFINITYID':
            return config.DEBUG ? config.affinityIds_url : config.affinityIds_url_prod
        case 'MCHANNEL':
            return config.DEBUG ? config.mchannel_url : config.mchannel_url_prod
        case 'MAILING':
            return config.DEBUG ? config.mailingType_url : config.mailingType_url_prod
        case 'AUDIENCE':
            return config.DEBUG ? config.audiences_url : config.audiences_url_prod
        case 'PUBLICATION':
            return config.DEBUG ? config.publications_url : config.publications_url_prod
        case 'PUBLICATION_SEARCH':
            return config.DEBUG ? config.publications_search_url : config.publications_search_url_prod
        case 'COMMUNICATION':
            return config.DEBUG ? config.communication_url : config.communication_url_prod
        case 'COMMUNICATION_SEARCH':
          return config.DEBUG ? config.communication_search_url : config.communication_search_url_prod
        case 'PROVINCE':
            return config.DEBUG ? config.stateProvince_url : config.stateProvince_url_prod
        case 'COMPANY':
            return config.DEBUG ? config.companies_url : config.companies_url_prod

        case 'COMPANYDATA':
            return config.DEBUG ? config.companies_data_url : config.companies_data_url_prod
        case 'COMPANYPRODUCTS':
            return config.DEBUG ? config.companyproducts_url : config.companyproducts_url_prod
        case 'COMPANYID':
            return config.DEBUG ? config.companiesCount_url : config.companiesCount_url_prod
        case 'COMPANYIMG':
            return config.DEBUG ? config.companyImg_url : config.companyImg_url_prod
        case 'COMPANYSEC':
            return config.DEBUG ? config.get_sec_name : config.get_sec_name_prod
        case 'PUBLICATIONID':
            return config.DEBUG ? config.publicationsIds_url : config.publicationsIds_url_prod
        case 'USERURL':
            return config.DEBUG ? config.user_url : config.user_url_prod
        case 'GETSECTORS':
            return config.DEBUG ? config.get_sectors_url : config.get_sectors_prod_url
        case 'ALLSECTORS':
            return config.DEBUG ? config.all_sectors_url : config.all_sectors_prod_url
        case 'DOWNLOADFILE':
            return config.DEBUG ? config.download_file_uploads_url : config.download_file_uploads_prod_url
        case 'MANAGEUSERS':
            return config.DEBUG ? config.manage_users_url : config.manage_users_prod_url
        case 'ADD_PRODUCT_FORM_URL':
            return config.DEBUG ? config.addproduct_form_url : config.addproduct_form_url
        case 'VIEW_PRODUCT_FORM_URL':
            return config.DEBUG ? config.viewproduct_form_url : config.viewproduct_form_url
        case 'EDIT_PRODUCT_FORM_URL':
            return config.DEBUG ? config.editprouct_url : config.editprouct_url
        case 'RESETPASSWORD':
            return config.DEBUG ? config.reset_password_url : config.reset_password_prod_url
        case 'ENABLEUSER':
            return config.DEBUG ? config.enable_user_url : config.enable_user_prod_url
        case 'USERROLE':
            return config.DEBUG ? config.user_role_url : config.user_role_prod_url
        case 'MANAGEREPORTS':
            return config.DEBUG ? config.manage_reports_url : config.manage_reports_prod_url
        case 'PANALISTREPORT':
                return config.DEBUG ? config.panalist_reports_url : config.panalist_reports_prod_url
        case 'PANALISTUPLOAD':
                return config.DEBUG ? config.panalist_reports_upload : config.panalist_reports_upload_prod_url
        case 'GETCLIENTREPORT':
            return config.DEBUG ? config.get_client_report_url : config.get_client_report_prod_url
        case 'DOWNLOADCLIENTREPORTFILE':
            return config.DEBUG ? config.download_client_report_url : config.download_client_report_prod_url
        case 'USERROLEANDPERMISSIONS':
            return config.DEBUG ? config.role_permission_url : config.role_permission_prod_url

        case 'PRODUCT_PREVIEW':
            return config.DEBUG ? config.image_list : config.image_list_prod

        case 'CLIENT_FRONTEND':
            return config.DEBUG ? config.frontend_url : config.frontend_url_prod

        case 'MANANGE_PDF_URL':
            return config.DEBUG ? config.manage_pdf_url : config.manage_pdf_url_prod

        case 'SIGNIN_URL':
            return config.DEBUG ? config.sign_in_url : config.sign_in_prod_url

        case 'ROLE_PERMISSION_URL':
            return config.DEBUG ? config.role_permission_url : config.role_permission_prod_url

        case 'DOMAIN_NAME':
            return config.DEBUG ? config.domain_name : config.domain_prod_name

        case 'SIGNOUT_URL':
            return config.DEBUG ? config.sign_out_url : config.sign_out_prod_url

        case 'AUTO_SIGNOUT_URL':
            return config.DEBUG ? config.auto_sign_out_url : config.auto_sign_out_prod_url

        case 'MODULE':
            return config.DEBUG ? config.module_url : config.module_prod_url

        case 'GET_MODULES':
            return config.DEBUG ? config.get_module_url : config.get_module_prod_url

        case 'MODULEOPERATIONS':
            return config.DEBUG ? config.module_operation_url : config.module_operation_prod_url

        case 'SIGNIN_URL':
            return config.DEBUG ? config.sign_in_url : config.sign_in_prod_url

        case 'ROLE_PERMISSION_URL':
            return config.DEBUG ? config.role_permission_url : config.role_permission_prod_url

        case 'DOMAIN_NAME':
            return config.DEBUG ? config.domain_name : config.domain_prod_name

        case 'SIGNOUT_URL':
            return config.DEBUG ? config.sign_out_url : config.sign_out_prod_url

        case 'AUTO_SIGNOUT_URL':
            return config.DEBUG ? config.auto_sign_out_url : config.auto_sign_out_prod_url

        case 'REFRESH_TOKEN_URL':
            return config.DEBUG ? config.refresh_token_url : config.refresh_token_prod_url

        case 'MANAGE_SERVICES':
            return config.DEBUG ? config.manage_services_url : config.manage_services_prod_url

        case 'MANAGE_ENDPOINT':
            return config.DEBUG ? config.manage_endpoint_url : config.manage_endpoint_prod_url
        case 'MANAGE_SERVICE_ENDPOINT_ROLE_PERMISSION':
            return config.DEBUG ? config.manage_service_endpoint_permission_url : config.manage_service_endpoint_permission_prod_url

        case 'PRODUCTPDF_URL':
            return config.DEBUG ? config.product_pdf_url : config.product_pdf_url_prod

        case 'PRODUCT_ADMIN_LOG':
            return config.DEBUG ? config.log_url : config.log_url_prod
        case 'PRODUCT_RAW_DATA':
            return config.DEBUG ? config.product_raw_data : config.product_raw_data_prod

        case 'PROCESSED_PRODUCT_URL':
            return config.DEBUG ? config.processed_prod_url : config.processed_prod_url_prod
        case 'MODULE_OPERATIONS_DATA':
            return config.DEBUG ? config.module_operation_data_url : config.module_operation_data_prod_url
        case 'DIRECT_MAIL_TEMP':
            return config.DEBUG ? config.direct_mail_temp_url : config.direct_mail_temp_url_prod_url
        case 'DIRECT_MAIL_PROCESS_PROD':
            return config.DEBUG ? config.direct_mail_processproduct_url : config.direct_mail_processproduct_url_prod_url
        case 'PANEL_DATA':
            return config.DEBUG ? config.manage_panel_url : config.manage_panel_prod_url
        case 'USER_LOG_DATA':
            return config.DEBUG ? config.user_log_data_url : config.user_log_data_prod_url
        case 'SEARCH_FORM_RAWDATA':
            return config.DEBUG ? config.search_form_rawdata_url : config.search_form_rawdata_prod_url
        case 'COMPANY_DATA':
            return config.DEBUG ? config.company_url : config.company_url_prod
        case 'BASE_TEMP_PRODUCT':
            return config.DEBUG ? config.base_temp_product : config.base_temp_product_prod
        case 'MASS_UPDATE':
            return config.DEBUG ? config.mass_save_url : config.mass_save_url_prod
        case 'DIRECT_MAIL_PDF':
            return config.DEBUG ? config.direct_mail_pdf : config.direct_mail_pdf_prod
        case 'ADD_NEW_PRODUCT':
            return config.DEBUG ? config.addnewproduct_url : config.addnewproduct_url_prod
        case 'DEDUPLICATES':
            return config.DEBUG ? config.deduplicates_url : config.deduplicates_url_prod
        case 'DEDUPLICATES_SEARCH':
            return config.DEBUG ? config.deduplicates_search_url : config.deduplicates_search_url_prod
        case 'HTTP_METHODS':
            return config.DEBUG ? config.http_methods_url : config.http_methods_url_prod
        case 'STATE_DATA_URL':
            return config.staging_contentsite_search
        case 'DB_USERS':
            return config.DEBUG ? config.db_users_url : config.db_users_url_prod
        case 'SYNC_DB_USERS':
            return config.DEBUG ? config.sync_db_users_url : config.sync_db_users_url_prod
        case 'LOG_MORE_INFO':
            return config.DEBUG ? config.log_more_info_url : config.log_more_info_url_prod

        case 'MOBILE_DIGITAL':
            return config.DEBUG ? config.mobile_digital_url : config.mobile_digital_url_prod
        case 'MOBILE_TEMP_PRODUCT':
            return config.DEBUG ? config.mobile_tempproduct_url : config.mobile_tempproduct_url_prod
        case 'PRODUCT_ARCHIEVE':
            return config.DEBUG ? config.product_archieve_url : config.product_archieve_url_prod
        case 'SOFT_DELETE':
            return config.DEBUG ? config.soft_delete_url : config.soft_delete_url_prod
        case 'PRODUCT_RESTORE':
            return config.DEBUG ? config.product_restore_url : config.product_restore_url_prod
        case 'HARD_DELETE':
            return config.DEBUG ? config.hard_delete_url : config.hard_delete_url_prod
        case 'GET_ALL_SECTIONS':
            return config.DEBUG ? config.section_url : config.section_url_prod
        case 'PRODUCER_MAIL':
            return config.DEBUG ? config.producer_mail : config.producer_mail_prod
        case 'BROKER_MAIL':
            return config.DEBUG ? config.broker_mail : config.broker_mail_prod
        case 'CONTACTUS_MAIL':
            return config.DEBUG ? config.contactus_mail : config.contactus_mail_prod
        case 'ROLE_PRODUCT_TAXONOMY':
            return config.DEBUG ? config.role_product_taxonomy_url : config.role_product_taxonomy_url_prod
        case 'ROLE_PRODUCT_TAXONOMY':
            return config.DEBUG ? config.role_product_taxonomy_url : config.role_product_taxonomy_url_prod
        case 'DMDUPLICATES':
            return config.DEBUG ? config.dmduplicates_url : config.dmduplicates_url_prod
        case 'DMDUPLICATES_SEARCH':
            return config.DEBUG ? config.dmduplicates_search_url : config.dmduplicates_search_url_prod
        case 'DAILY_STATUS':
                return config.DEBUG ? config.dailydtatusreport_search_url : config.dailydtatusreport_search_url_prod
        case 'PRODUCER_PANELIST_REPORT':
            return config.DEBUG ? config.producer_panelist_report_url : config.producer_panelist_report_url_prod
        case 'DOWNLOAD_PRODUCER_PANELIST_REPORT':
            return config.DEBUG ? config.download_producer_panelist_report_url : config.download_producer_panelist_report_url_prod
        case 'BAGS_DIRECTMAIL_IMPORT':
            return config.DEBUG ? config.bags_directmail_import_url : config.bags_directmail_import_url_prod
        case 'BAGS_DIRECTMAIL_UPDATE':
            return config.DEBUG ? config.bags_directmail_update_url : config.bags_directmail_update_url_prod
        case 'DM_REPORT':
            return config.DEBUG ? config.dm_report : config.dm_report_prod
        case 'COMPANY_APPROVE_LIST':
            return config.DEBUG ? config.company_approve_list : config.company_approve_list_prod
        case 'SIDEBAR_SORTING_UPDATE':
            return config.DEBUG ? config.sidebar_sorting_update_url : config.sidebar_sorting_update_prod_url
        case 'PANELIST_REPORT':
            return config.DEBUG ? config.panelist_report_url : config.panelist_report_url_prod
        case 'DOWNLOAD_PANELIST_REPORT':
            return config.DEBUG ? config.download_panelist_report_url : config.download_panelist_report_url_prod
        case 'DELETE_REDIS_KEYS':
            return config.DEBUG ? config.clear_redis_cache_url : config.clear_redis_cache_url_prod
        case 'REDIS_KEYS':
            return config.DEBUG ? config.redis_keys_url : config.redis_keys_url_prod
        case 'GET_REDIS_KEY_DATA':
            return config.DEBUG ? config.redis_key_data_url : config.redis_key_data_url_prod
        case 'USER_TRACKER_REPORT':
            return config.DEBUG ? config.user_tracking_url : config.user_tracking_url_prod
        case 'GET_ALL_CLIENTS':
            return config.DEBUG ? config.client_list_url : config.client_list_url_prod
        case 'EXPORT_DATA_URL':
            return config.DEBUG ? config.export_data_url : config.export_data_url_prod
        case 'COMPARE_PDF':
            return config.DEBUG ? config.compare_pdf : config.compare_pdf_prod
        case 'UPLOAD_FILE':
            return config.DEBUG ? config.upload_file : config.upload_file_prod
        case 'UPLOAD_FILE1':
            return config.DEBUG ? config.upload_file1 : config.upload_file_prod1
        case 'ASSING_USER_URL':
                return config.DEBUG ? config.assign_user_url : config.assign_user_url_prod
        case 'PIXEL_REPORT':
            return config.DEBUG ? config.pixel_report: config.pixel_report_prod
        case 'PIXEL_REPORT_DOWNLOAD':
                return config.DEBUG ? config.pixel_report_download: config.pixel_report_download_prod
        case 'ANNOTATION_TOOL_URL':
                return config.DEBUG ? config.annotation_tool_url: config.annotation_tool_url_prod
        case 'Easy_OCR_URL':
                    return config.DEBUG ? config.easy_ocr_baseurl: config.easy_ocr_baseurl_prod
        case 'CSV2_PRODUCT_BUCKET_PATH':
            return config.DEBUG ? config.csv2_product_bucket_path: config.csv2_product_bucket_path_prod
        case 'CSV2_ANNOTATION_REVIEWED':
            return config.DEBUG ? config.csv2_annotation_reviewed_url : config.csv2_annotation_reviewed_url_prod
        case 'ADD_PRODUCT_FORM_URLV2':
            return config.DEBUG ? config.addproduct_form_urlv2 : config.addproduct_form_urlv2
        case 'MERGE_BASE_URL':
            return config.DEBUG ? config.merge_base_url : config.merge_base_url_prod
    }
}
export default url
